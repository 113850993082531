const ROLES_FOR_APP = {
  DASHBOARD_VIEW: "view",

  NAVER_KEYWORD_LIST: "naver_keyword",
  NAVER_KEYWORD_CREATE: "naver_keyword",
  NAVER_KEYWORD_UPDATE: "naver_keyword",
  NAVER_KEYWORD_DELETE: "naver_keyword",

  CAR_KEYWORD_LIST: "car_keyword",
  CAR_KEYWORD_CREATE: "car_keyword",
  CAR_KEYWORD_UPDATE: "car_keyword",
  CAR_KEYWORD_DELETE: "car_keyword",
  CAR_KEYWORD_IMPORT: "car_keyword",

  CAR_CATEGORY_LIST: "car_keyword",
  CAR_CATEGORY_CREATE: "car_keyword",
  CAR_CATEGORY_UPDATE: "car_keyword",
  CAR_CATEGORY_DELETE: "car_keyword",
  CAR_CATEGORY_IMPORT: "car_keyword",

  MOVIE_KEYWORD_LIST: "movie",
  MOVIE_KEYWORD_CREATE: "movie",
  MOVIE_KEYWORD_UPDATE: "movie",
  MOVIE_KEYWORD_DELETE: "movie",
  MOVIE_KEYWORD_IMPORT: "movie",

  MOVIE_CATEGORY_LIST: "movie_category",
  MOVIE_CATEGORY_CREATE: "movie_category",
  MOVIE_CATEGORY_UPDATE: "movie_category",
  MOVIE_CATEGORY_DELETE: "movie_category",
  MOVIE_CATEGORY_IMPORT: "movie_category",

  COIN_LIST: "exchange_rate",
  COIN_CREATE: "exchange_rate",
  COIN_UPDATE: "exchange_rate",
  COIN_DELETE: "exchange_rate",

  EXCHANGE_RATE_LIST: "exchange_rate",
  EXCHANGE_RATE_CREATE: "exchange_rate",
  EXCHANGE_RATE_UPDATE: "exchange_rate",
  EXCHANGE_RATE_DELETE: "exchange_rate",

  EXCHANGE_RATE_CATEGORY_LIST: "exchange_rate",
  EXCHANGE_RATE_CATEGORY_CREATE: "exchange_rate",
  EXCHANGE_RATE_CATEGORY_UPDATE: "exchange_rate",
  EXCHANGE_RATE_CATEGORY_DELETE: "exchange_rate",

  STOCK_KEYWORD_LIST: "stock",
  STOCK_KEYWORD_CREATE: "stock",
  STOCK_KEYWORD_UPDATE: "stock",
  STOCK_KEYWORD_DELETE: "stock",
  STOCK_KEYWORD_IMPORT: "stock",

  STOCK_CATEGORY_LIST: "stock_category",
  STOCK_CATEGORY_CREATE: "stock_category",
  STOCK_CATEGORY_UPDATE: "stock_category",
  STOCK_CATEGORY_DELETE: "stock_category",
  STOCK_CATEGORY_IMPORT: "stock_category",

  SPORT_TEAM_LIST: "sports_team",
  SPORT_TEAM_CREATE: "sports_team",
  SPORT_TEAM_UPDATE: "sports_team",
  SPORT_TEAM_DELETE: "sports_team",
  SPORT_TEAM_IMPORT: "sports_team",

  SPORT_SCHEDULE_LIST: "sports_schedule",
  SPORT_SCHEDULE_CREATE: "sports_schedule",
  SPORT_SCHEDULE_UPDATE: "sports_schedule",
  SPORT_SCHEDULE_DELETE: "sports_schedule",
  SPORT_SCHEDULE_IMPORT: "sports_schedule",

  SPORT_CATEGORY_LIST: "sport_category",
  SPORT_CATEGORY_CREATE: "sport_category",
  SPORT_CATEGORY_UPDATE: "sport_category",
  SPORT_CATEGORY_DELETE: "sport_category",
  SPORT_CATEGORY_IMPORT: "sport_category",

  LOTTO_SCHEDULE_LIST: "sports_schedule", //

  TREND_INDEX_CATEGORY_LIST: "trend_index_category",
  TREND_INDEX_CATEGORY_CREATE: "trend_index_category",
  TREND_INDEX_CATEGORY_UPDATE: "trend_index_category",
  TREND_INDEX_CATEGORY_DELETE: "trend_index_category",

  TREND_INDEX_LIST: "trend_index",
  TREND_INDEX_CREATE: "trend_index",
  TREND_INDEX_UPDATE: "trend_index",
  TREND_INDEX_DELETE: "trend_index",

  TEMPLATE_TITLE_LIST: "news_title_list",
  TEMPLATE_TITLE_CREATE: "news_title_create",
  TEMPLATE_TITLE_UPDATE: "news_title_update",
  TEMPLATE_TITLE_DELETE: "news_title_remove",
  TEMPLATE_TITLE_COPY: "news_title_copy",

  TEMPLATE_BODY_LIST: "news_content_list",
  TEMPLATE_BODY_CREATE: "news_content_create",
  TEMPLATE_BODY_UPDATE: "news_content_update",
  TEMPLATE_BODY_DELETE: "news_content_remove",
  TEMPLATE_BODY_COPY: "news_content_copy",

  INFO_GRAPHIC_LIST: "info_graphic",
  INFO_GRAPHIC_CREATE: "info_graphic",
  INFO_GRAPHIC_UPDATE: "info_graphic",
  INFO_GRAPHIC_DELETE: "info_graphic",

  VARIABLE_DEFINITION_LIST: "variable_definition_list",
  VARIABLE_DEFINITION_CREATE: "variable_definition_create",
  VARIABLE_DEFINITION_UPDATE: "variable_definition_update",
  VARIABLE_DEFINITION_DELETE: "variable_definition_remove",

  CAMPAIGN_LIST: "campaign_list",
  CAMPAIGN_CREATE: "campaign_create",
  CAMPAIGN_UPDATE: "campaign_update",
  CAMPAIGN_DELETE: "campaign_remove",
  CAMPAIGN_SET_CAMPAIGN_SPORT: "campaign_set_campaign_sports",
  CAMPAIGN_SET_CAMPAIGN_LOTTO: "campaign_set_campaign_sports", //
  CAMPAIGN_COPY: "campaign_copy",
  CAMPAIGN_SAVE: "campaign_create",
  CAMPAIGN_DOWNLOAD: "campaign_list",

  MEDIA_LIST: "media_list",
  MEDIA_CREATE: "media_create",
  MEDIA_UPDATE: "media_update",
  MEDIA_DELETE: "media_remove",
  MEDIA_REFRESH_KEY: "media_refersh_key",
  MEDIA_CREATE_ACCOUNT_CONSOLE: "media_create_account_media_console",

  NOTIFICATION_LIST: "notice_list",
  NOTIFICATION_CREATE: "notice_create",
  NOTIFICATION_UPDATE: "notice_update",
  NOTIFICATION_DELETE: "notice_remove",

  NEWS_LIST: "news_list",
  NEWS_CREATE: "news_create",
  NEWS_UPDATE: "news_update",
  NEWS_DELETE: "news_remove",
  NEWS_DOWNLOAD_IMAGE: "news_download",
  NEWS_DOWNLOAD_WORD: "news_download",
  NEWS_PREVIEW: "news_list",
  NEWS_REFRESH: "news_refresh_news",
  NEWS_RESET_STATUS: "news_reset_status",
  NEWS_SCRIPT_TRACKING: "news_list",
  NEWS_SET_CONFIRM: "news_update",
  NEWS_DOWNLOAD_FILE: "news_download",
  NEWS_SEND_EMAIL: "news_update", //
  NEWS_REST_NEWS: "news_update", //

  NEWS_STATISTICS_LIST: "news_statistics",
  MEDIA_LOG_LIST: "media_log",
  MEDIA_STATISTICS_LIST: "media_statistics",
  KEYWORD_INFLOW_LIST: "keyword_inflow",
  KEYWORD_ANALYSIS_LIST: "keyword_inflow", //
  KEYWORD_STATISTICS_LIST: "keyword_statistics",
  CAMPAIGN_STATISTICS_LIST: "campaign_statistics",
  CATEGORY_STATISTICS_LIST: "category_statistics",
  VISITOR_STATISTICS_LIST: "visitor_statistics",
  MEDIA_RANKING_LIST: "visitor_statistics", //

  ROLE_LIST: "role_list",
  ROLE_CREATE: "role_create",
  ROLE_UPDATE: "role_update",
  ROLE_DELETE: "role_remove",

  USER_LIST: "user_list",
  USER_CREATE: "user_create",
  USER_UPDATE: "user_update",
  USER_DELETE: "user_remove",

  SSO_LIST: "sso_list",
  SSO_MESSAGEHUB: "sso_messagehub",

  CONFIGURATION_LIST: "campaign_list",
  CONFIGURATION_CREATE: "campaign_create",
  CONFIGURATION_UPDATE: "campaign_update",
  CONFIGURATION_DELETE: "campaign_remove",
};

const isHavePermissionRole = (
  keyRoles: string | string[],
  permissions: { id: string | number; name: string }[] = []
): boolean => {
  return true;
  const roles = typeof keyRoles === "string" ? [keyRoles] : keyRoles;
  return permissions?.some((item) => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };

import { createAsyncThunk } from "@reduxjs/toolkit";
import collectApi from "api/collectApi";
import { formatQueryParams } from "helpers/format";


export const getCollectedEmails = createAsyncThunk(
  "Collected Emails",
  async (params: any = {}) => {
    try {
      const response = await collectApi.collectedEmails(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const downloadExcelCollectedEmails = async (params: any = {}) => {
  try {
    const response = await collectApi.downloadExcelCollectedEmails(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

import { Option } from "api/types/_public";
import imgEmail from "../assets/images/platform/mail.svg";
import imgGoogle from "../assets/images/platform/google.svg";
import imgKakao from "../assets/images/platform/kakao.svg";
import imgNaver from "../assets/images/platform/naver.svg";
import imgApple from "../assets/images/platform/apple.svg";
import imgAmazon from "../assets/images/platform/amazon.svg";
import imgFirebase from "../assets/images/platform/firebase.svg";

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  // {
  //   label: 'Except Holiday In Korea',
  //   value: 'except_holiday_in_Korea',
  // }
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const SCHEDULE_CAMPAIGN_STATISTIC_OPTIONS: Option[] = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const ACTION_TYPE_CAMPAIGN = {
  SAVE: "save",
  POST: "post",
};


export const METHOD_OPTIONS: Option[] = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
];




export const BROWSER_OPTIONS: Option[] = [
  {
    label: "Browser_Chrome",
    value: "Chrome",
  },
  {
    label: "Browser_Safari",
    value: "Safari",
  },
  {
    label: "Browser_Unknown_Browser",
    value: "Unknown Browser",
  },
];

export const OS_OPTIONS: Option[] = [
  {
    label: "OS_iOS",
    value: "iOS",
  },
  {
    label: "OS_Mac",
    value: "Mac",
  },
  {
    label: "OS_Windows",
    value: "Windows",
  },
  {
    label: "OS_Android",
    value: "Android",
  },
  {
    label: "OS_Etc",
    value: "Etc",
  },
];

export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];


export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: "scroll",
  MODAL: "modal",
};

export const ARR_INDEX_TEXT_DAY_OF_WEEK: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];


// User
export const ENUM_STATUS_USER = {
  PENDING: 'pending',
  ACTIVATED: 'activated',
  DISABLED: 'disabled'
};

export const COLOR_STATUS_USER: any = {
  [ENUM_STATUS_USER.PENDING]: 'primary',
  [ENUM_STATUS_USER.ACTIVATED]: 'success',
  [ENUM_STATUS_USER.DISABLED]: 'danger'
};

export const STATUS_USER_OPTION = [
  {
    label: 'Pending',
    value: ENUM_STATUS_USER.PENDING
  },
  {
    label: 'Activated',
    value: ENUM_STATUS_USER.ACTIVATED
  },
  {
    label: 'Disabled',
    value: ENUM_STATUS_USER.DISABLED
  }];


// Collect Email
export const ENUM_STATUS_COLLECT_EMAIL = {
  NO_VERIFY: '0',
  VERIFIED: '1',
  EXPIRED: '2'
};

export const COLOR_STATUS_COLLECT_EMAIL: any = {
  [ENUM_STATUS_COLLECT_EMAIL.VERIFIED]: 'success',
  [ENUM_STATUS_COLLECT_EMAIL.NO_VERIFY]: 'warning',
  [ENUM_STATUS_COLLECT_EMAIL.EXPIRED]: 'danger'
};

export const STATUS_COLLECT_EMAIL_OPTION = [
  {
    label: 'Verified',
    value: ENUM_STATUS_COLLECT_EMAIL.VERIFIED
  },
  {
    label: 'No Verify',
    value: ENUM_STATUS_COLLECT_EMAIL.NO_VERIFY
  },
  {
    label: 'Expired',
    value: ENUM_STATUS_COLLECT_EMAIL.EXPIRED
  }];


export const LIST_OPTION_OS = [
  {
    label: "iOS",
    value: "ios",
  },
  {
    label: "Android",
    value: "android",
  }
];

export const getPlatFormImage = (type: string) => {
  switch (type) {
    case "default":
      return imgEmail;
    case "google":
      return imgGoogle;
    case "naver":
      return imgNaver;
    case "kakao":
      return imgKakao;
    case "apple":
      return imgApple;
    case "amazon":
      return imgAmazon;
    case "firebase":
      return imgFirebase;
    default:
      return '';
  }
}
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ROUTES } from "routes/allRoutes";
import config from "config";

const Navdata = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { userPermissions } = useRole();

  const [menuActive, setMenuActive] = useState<string>("DASHBOARD");


  // Multi Level
  const [isMultiLevel, setIsMultiLevel] = useState<boolean>(false);
  const [isLevel1, setIsLevel1] = useState<boolean>(false);
  const [isLevel2, setIsLevel2] = useState<boolean>(false);

  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  const redirectMessageHub = () => {
    const { message_hub_sso_redirect_url } = config;
    const accessToken: any = localStorage.getItem("access_token");

    if (message_hub_sso_redirect_url && accessToken) {
      return `${message_hub_sso_redirect_url}?sso_token_newshub=${accessToken}`;
    } else {
      return "/#";
    }
  };

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history]);

  const menuItems: any = [
    {
      id: "collected-emails",
      label: t("Collected Emails"),
      icon: <FeatherIcon icon="at-sign" className="icon-dual" />,
      link: ROUTES.COLLECTED_EMAILS,
      click: function (e: any) {
        setMenuActive((prev) => "Collected Emails");
        setIsCurrentState((prev) => "Collected Emails");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "Collected Emails",
      isShow: true,
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;

import { createSlice } from "@reduxjs/toolkit";
import { getCollectedEmails } from "./thunk";

export interface IState {
  collectedEmails: any;
  isCollectedEmailLoading: boolean;
  isCollectedEmailSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  collectedEmails: null,
  isCollectedEmailLoading: false,
  isCollectedEmailSuccess: false,

  error: {},
};

const CollectSlice = createSlice({
  name: "Collect",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Collected Email
    builder.addCase(getCollectedEmails.pending, (state: IState, action: any) => {
      state.isCollectedEmailLoading = true;
    });
    builder.addCase(getCollectedEmails.fulfilled, (state: IState, action: any) => {
      state.collectedEmails = action.payload.data;
      state.isCollectedEmailSuccess = true;
      state.isCollectedEmailLoading = false;
    });
    builder.addCase(getCollectedEmails.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCollectedEmailSuccess = false;
      state.isCollectedEmailLoading = false;
    });
  },
});

export default CollectSlice.reducer;
const momentTimeZone = require('moment-timezone');


export const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-IN').format(num);
}

export const formatNumberWithCommas = (number: number | string = 0) => {
    if (String(number) === 'NaN') {
        return 0;
    }
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatNumberWithoutCommas = (number: number | string = 0) => {
    return parseFloat(String(number).replace(/,/g, ''));
};

export const formatDateStringTo2Row = (date: string = '') => {
    const arr = String(date).split(' ');
    return `${arr[0]} <br/> ${arr[1]}`;
}


export const formatQueryParams = (query: any = {}) => {
    const clone = structuredClone(query);
    delete clone.time_request;
    delete clone.time_request_out;
    delete clone.time_request_inner;
    return clone;
}

export const formatLinkHTTPS = (linkString: string = '') => {
    if ((String(linkString).includes('https://') || String(linkString).includes('http://'))) {
        return linkString;
    }
    return `https://${linkString}`;
}

export const renderUrlSearchKeywordInNaver = (keyword: string = '') => {
    return `https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=${keyword}`;
}

export const renderUrlSearchLookingUpByIP = (ip: string = '') => {
    return `https://whatismyipaddress.com/ip/${ip}`;
}

export const formatDateStringToKorea = (dateString: string = '') => {
    const date = momentTimeZone(dateString).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss");
    return date;
}

export const formatSummaryStatistics = (data: any) => {
    console.log(data);
    const data_format = data?.combined?.map((item: any) => (
        {
            ...item,
            service: {
                hot: data?.hot?.data?.find((h: any) => String(h?.date) === String(item?.date)),
                wallpaper: data?.wallpaper?.data?.find((h: any) => String(h?.date) === String(item?.date)),
                snap: data?.snap?.data?.find((h: any) => String(h?.date) === String(item?.date)),
                photoCardNews: data?.photoCardNews?.data?.find((h: any) => String(h?.date) === String(item?.date)),
            }

        }
    ));
    const data_total = {
        date: '',
        totalLikes: data_format?.reduce((total: number, item: any) => (Number(item?.totalLikes) + total), 0),
        totalViews: data_format?.reduce((total: number, item: any) => (Number(item?.totalViews) + total), 0),
        totalShares: data_format?.reduce((total: number, item: any) => (Number(item?.totalShares) + total), 0),
        totalComments: data_format?.reduce((total: number, item: any) => (Number(item?.totalComments) + total), 0),
        totalDownloads: data_format?.reduce((total: number, item: any) => (Number(item?.totalDownloads) + total), 0),
    };
    return ({
        data: data_format,
        total: data_total
    });
}
import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/users/emails";
const collectApi = {
  collectedEmails(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },
  downloadExcelCollectedEmails(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },
};

export function uploadFiles(files: any) {
  const url = "/assets/direct";
  return api.post(url, files, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export default collectApi;

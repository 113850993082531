import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";


//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

import CollectedEmailList from "pages/CollectedEmail";

const ROUTES = {
  COLLECTED_EMAILS: "/collected-emails",

  USER_LIST: "/user/list",
  USER_ROLE: "/user/role",
  USER_PROFILE: "/user/profile",

  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  PROFILE_SETTING: "/pages-profile-settings",

};

const authProtectedRoutes = [
  { path: ROUTES.COLLECTED_EMAILS, component: <CollectedEmailList /> },
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to={ROUTES.COLLECTED_EMAILS} />,
  },
  { path: "*", component: <Navigate to={ROUTES.COLLECTED_EMAILS} /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

];

export { authProtectedRoutes, publicRoutes, ROUTES };

//Include Both Helper File with needed methods

import authApi from "api/authApi";
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
} from "./reducer";
import { removeAccessToken, removeRefreshToken } from "helpers/cookie";
import { push } from "helpers/navigation";

export const loginUser =
  (user: any, history: any, href: string) => async (dispatch: any) => {
    try {
      let response = await authApi.login({
        email: user.email,
        password: user.password,
        type: 2,
        // device_id: user.device_id
      });
      if (response) {
        let finallogin: any = response?.data;

        const fortmatData = {
          user: finallogin?.profile,
          access_token: finallogin?.access_token?.token,
          refresh_token: finallogin?.refresh_token?.token,
          meta: {
            access_token: finallogin?.access_token,
            refresh_token: finallogin?.refresh_token,
          },
        };
        localStorage.setItem("authUser", JSON.stringify(fortmatData));
        dispatch(loginSuccess(finallogin));
        history(href);
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const loginUserWithSSO =
  (user: any, sso_callback: any) => async (dispatch: any) => {
    try {
      let response = await authApi.login({
        email: user.email,
        password: user.password,
        device_id: user.device_id,
      });
      if (response) {
        let finallogin: any = response?.data;
        const {
          meta: {
            access_token: { token },
          },
        } = finallogin;
        localStorage.setItem("authUser", JSON.stringify(finallogin));
        dispatch(loginSuccess(finallogin));
        window.location.href = `${sso_callback}?sso_token=${token}`;
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const logoutUser = () => async (dispatch: any) => {
  try {
    push("/login");
    localStorage.removeItem("authUser");
    removeAccessToken();
    removeRefreshToken();
    // await authApi.logout();
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    push("/login");
    localStorage.removeItem("authUser");
    removeAccessToken();
    removeRefreshToken();
    // dispatch(apiError(error));
  } finally {
    push("/login");
    localStorage.removeItem("authUser");
    removeAccessToken();
    removeRefreshToken();
  }
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};

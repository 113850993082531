import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import withRouter from '../components/Common/withRouter';

//import actions
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebarVisibility
} from "../store/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import BackToTop from './BackToTop';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import { useTranslation } from 'react-i18next';


const Layout = (props: any) => {
    const [headerClass, setHeaderClass] = useState("");
    const dispatch = useDispatch<any>();

    const refTime = React.useRef<any>(null);
    const { durationResponses } = useDurationResponses();
    const { t, i18n } = useTranslation();
    const [isShowLog, setIsShowLog] = useState<boolean>(false);

    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutType: layout.layoutType,
            leftSidebarType: layout.leftSidebarType,
            layoutModeType: layout.layoutModeType,
            layoutWidthType: layout.layoutWidthType,
            layoutPositionType: layout.layoutPositionType,
            topbarThemeType: layout.topbarThemeType,
            leftsidbarSizeType: layout.leftsidbarSizeType,
            leftSidebarViewType: layout.leftSidebarViewType,
            leftSidebarImageType: layout.leftSidebarImageType,
            preloader: layout.preloader,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
        })
    );
    // Inside your component
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        preloader,
        sidebarVisibilitytype
    } = useSelector(selectLayoutProperties);
    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });
    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }
    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            sidebarVisibilitytype ||
            leftSidebarImageType ||
            preloader
        ) {
            window.dispatchEvent(new Event('resize'));
            dispatch(changeLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
            dispatch(changeSidebarTheme(leftSidebarType));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayoutWidth(layoutWidthType));
            dispatch(changeLayoutPosition(layoutPositionType));
            dispatch(changeTopbarTheme(topbarThemeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarVisibility(sidebarVisibilitytype));
        }
    }, [layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        sidebarVisibilitytype,
        leftSidebarImageType,
        preloader,
        dispatch]);
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value: any) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    useEffect(() => {
        if (sidebarVisibilitytype === 'show' || layoutType === "vertical" || layoutType === "twocolumn") {
            document.querySelector(".hamburger-icon")?.classList.remove('open');
        } else {
            document.querySelector(".hamburger-icon")?.classList.add('open');
        }
    }, [sidebarVisibilitytype, layoutType]);


    useEffect(() => {
        if (refTime.current) {
            clearTimeout(refTime.current);
            setIsShowLog((prev) => true);
        }
        refTime.current = setTimeout(() => {
            setIsShowLog((prev) => false);
        }, 3000);
        return () => {
            clearTimeout(refTime.current);
        }
    }, [JSON.stringify(durationResponses)]);

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode} />
                <Sidebar layoutType={layoutType} />
                <div className="main-content">{props.children}
                    <Footer />
                    <BackToTop />
                    {durationResponses && (<div
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: `${isShowLog ? 0 : 'calc(35px - var(--vz-vertical-menu-width))'}`,
                            zIndex: 1500,
                            minWidth: 'var(--vz-vertical-menu-width)',
                            background: 'rgb(222 241 251)',
                            color: 'red',
                            padding: '0px 0px 0px 20px',
                            textAlign: 'left',
                            transition: '.15s ease all'
                        }}
                        className="d-flex justify-content-between align-items-center"
                    >
                        <div>
                            {durationResponses?.map((item: any, index: number) => (
                                <p key={index} style={{ margin: 0 }} className={isShowLog ? 'me-2' : 'd-none'}>{t(item?.name || '')} : {Number(item?.time || 0) / 1000} {t('seconds')} </p>
                            ))}
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ padding: '10px', fontSize: '15px', cursor: 'pointer', color: '#fff', background: '#25a0e270', fontWeight: 800 }}
                            onClick={() => setIsShowLog((prev: boolean) => !prev)}
                        >
                            {isShowLog ? <i className="ri-arrow-left-line align-bottom"></i> : <i className="ri-arrow-right-line align-bottom"></i>}
                        </div>
                    </div>)}
                </div>
            </div>
        </React.Fragment >
    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);
import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { AxiosResponse } from "axios";
import { IUser } from "./types/_user";
const api = new APIClient();

const path = "/users";
const userApi = {
  users(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },

  createUser(params: any) {
    return api.post(path, params);
  },

  updateUser(id: any, params: any) {
    return api.put(`${path}/${id}`, params);
  },

  deleteUser(id: any) {
    return api.delete(`${path}/${id}`);
  },
  
  postUser(dataForm: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },

  putUser(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },

  getUser(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },

  putProfiles(dataForm: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/profiles`;
    return api.put(url, dataForm)
  },

  getDeletedUsers(params: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/deleted-users`;
    return api.get(url, params)
  },
};

export default userApi;

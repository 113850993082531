import { createSlice } from "@reduxjs/toolkit";
import { getUsers, getDeletedUsers } from "./thunk";

export interface IState {
  users: any;
  isUserLoading: boolean;
  isUserSuccess: boolean;

  deletedUsers: any,
  isDeletedUserLoading: boolean,
  isDeletedUserSuccess: boolean,

  error: any;
}

export const initialState: IState = {
  users: null,
  isUserLoading: false,
  isUserSuccess: false,

  deletedUsers: null,
  isDeletedUserLoading: false,
  isDeletedUserSuccess: false,

  error: {},
};

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Users
    builder.addCase(getUsers.pending, (state: IState, action: any) => {
      state.isUserLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state: IState, action: any) => {
      state.users = action.payload.data;
      state.isUserSuccess = true;
      state.isUserLoading = false;
    });
    builder.addCase(getUsers.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUserSuccess = false;
      state.isUserLoading = false;
    });

    //get Deleted Users
    builder.addCase(getDeletedUsers.pending, (state: IState, action: any) => {
      state.isDeletedUserLoading = true;
    });
    builder.addCase(getDeletedUsers.fulfilled, (state: IState, action: any) => {
      state.deletedUsers = action.payload.data;
      state.isDeletedUserSuccess = true;
      state.isDeletedUserLoading = false;
    });
    builder.addCase(getDeletedUsers.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDeletedUserSuccess = false;
      state.isDeletedUserLoading = false;
    });
  },
});

export default UserSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import UserApi from "api/userApi";
import { formatQueryParams } from "helpers/format";

export const getUsers = createAsyncThunk("Users", async (params: any = {}) => {
  try {
    const response = await UserApi.users(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getDeletedUsers = createAsyncThunk("DeletedUsers", async (params: any = {}) => {
  try {
    const response = await UserApi.getDeletedUsers(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getUser =  async(id: string | number ,  params: any = {}) => {
  try{
    const response = await UserApi.getUser(id, params)
    return response;
  }catch (error) {
    return error;
  }
};

export const postUser = async (data: any = {}) => {
  try{
    const response = await UserApi.postUser(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putProfiles = async (data: any = {}) => {
  try{
    const response = await UserApi.putProfiles(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getUsersByNormal =  async(params: any = {}) => {
  try{
    const response = await UserApi.users(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

export const putUser = async (id: string | number , data: any = {}) => {
  try{
    const response = await UserApi.putUser(id, data);
    return response;
  }catch (error) {
    return error;
  }
};
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Dfesta</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                {t("Design & Develop Metacrew")}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
